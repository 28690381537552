import React from 'react';
import Header from '../components/Header';
import {Helmet} from "react-helmet";
import Footer from "../components/Footer"


// Style
import './cgu.scss';


// markup
const CGU = () => {

  return (
    <main className={"cgu"}>
      <Helmet title={"Scorf - Condition d'Utilisation Générales"}>
        <html lang="fr" />
        <meta charSet="utf-8" />
        <meta name='title' content='Scorf'/>
        <meta name="description" content="Scorf construit un outil BI unique capable de comprendre la 'grammaire' comptable qui se cache derrière chaque base de données financière - Grand livre, écritures de journal. Cela lui permet de fournir des analyses avancées et un suivi en temps réel aux départements financiers des PME ainsi qu'aux analystes et consultants financiers externes." />
        <link rel="canonical" href="https://www.scorf.io/cgu" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-63J6THBWF0"></script>
        <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-63J6THBWF0');`}}>
          
        </script>
      </Helmet>
      <title>Condition d'Utilisation Générales</title>
      <Header headerColor={'white'}/>
      <h1>Conditions Générales d'Utilisation</h1>
      <p className="date">Dernière mise à jour le 29 mars 2021 </p>
      <div className="content">
        <p className='introduction'>À propos de nous : La société SCORF SAS, au capital de 8 000,00 euros, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 853 834 059, dont le siège social est situé 33 bis route de la Reine à Boulogne-Billancourt 92100. </p>
        <h3>1. Dispositions générales</h3>
        <div className="line">
          <p>1.1</p>
          <p>Les présentes CGU décrivent les conditions dans lesquelles vous pouvez utiliser le site internet de SCORF (notre « Site ») en tant que visiteur ou avant de vous connecter à l'application SCORF (l'« Application »). </p>
        </div>
        <div className="line">
          <p>1.2</p>
          <p>Nous vous conseillons de lire ces Conditions Générales d'Utilisation avec attention avant d'utiliser notre Site puisqu'elles ont vocation à s'appliquer à une telle utilisation. Nous vous recommandons, en outre, d'en imprimer un exemplaire et de le conserver pour votre information. </p>
        </div>
        <div className="line">
          <p>1.3</p>
          <p>En utilisant notre Site, vous acceptez pleinement les présentes Conditions Générales d'Utilisation et vous engagez à les respecter. </p>
        </div>
        <div className="line">
          <p>1.4</p>
          <p>Si vous n'acceptez pas les Conditions Générales d'Utilisation, nous vous recommandons de ne pas utiliser notre Site.  </p>
        </div>
        <div className="line">
          <p>1.5</p>
          <p>Chacune des clauses des présentes Conditions générales d'Utilisation sont distinctes. Si une ou plusieurs de ces dispositions sont ou deviennent nulles (en tout ou en partie) au regard du droit en vigueur ou d'une décision de justice devenue définitive, elles seraient alors réputées non écrites sans entraîner la nullité des autres dispositions des présentes Conditions Générales d'Utilisation. </p>
        </div>
        <div className="line">
          <p>1.6</p>
          <p>Nous pourrons, à votre demande ou à notre initiative, modifier ou mettre à jour les Conditions Générales d'Utilisation afin de nous assurer qu'elles sont conformes au droit applicable. </p>
        </div>
        <h3>2. Autres dispositions applicables </h3>
        <div className="line">
          <p>2.1</p>
          <p>Pour accéder pleinement à nos services, vous devrez souscrire à un abonnement et vous enregistrer. Dans ce cas, vous serez aussi soumis au respect du contrat de souscription ainsi que des conditions générales d'utilisation de l'Application. </p>
        </div>
        <div className="line">
          <p>2.2</p>
          <p>Le traitement de vos données personnelles par SCORF dans le cadre de l'utilisation de notre Site est soumis au respect des dispositions de notre politique de confidentialité disponible en ligne. </p>
        </div>
        <div className="line">
          <p>2.3</p>
          <p>Vous êtes la seule personne autorisée à utiliser votre identifiant et votre mot de passe pour vous connecter et utiliser notre Application. Vous devez veiller à garder ces informations confidentielles et à ne les divulguer ou les communiquer à personne. Si vous pensez qu'une personne utilise votre identifiant et votre mot de passe sans votre autorisation, vous devez nous prévenir immédiatement par mail (support@scorf.io). </p>
        </div>
        <div className="line">
          <p>2.4</p>
          <p>Si nous avons des raisons de penser que la connexion à votre compte résulte d'une faille de sécurité ou d'un usage frauduleux de vos identifiants, il pourra vous être demandé de changer de mot de passe ou, le cas échéant, nous pourrons suspendre l'accès à votre compte. </p>
        </div>
        <h3>3. Modifications du site </h3>
        <div className="line">
          <p>3.1</p>
          <p>Nous pourrons mettre à jour notre Site et en modifier le contenu à tout moment. Nous vous rappelons cependant que nous n'avons aucune obligation de mettre notre Site à jour et qu'il peut donc contenir des informations datées. </p>
        </div>
        <div className="line">
          <p>3.2</p>
          <p>Nous ne sommes en aucune manière en mesure d'apporter la garantie que notre Site ou le contenu affiché sur celui-ci est exempt d'erreurs ou d'omissions ou qu'il répondra à vos attentes.  </p>
        </div>
        <h3>4. Accès au site </h3>
        <div className="line">
          <p>4.1</p>
          <p>L'accès à notre Site vous est accordé à titre gratuit et de manière temporaire. Nous ne sommes en aucune manière en mesure d'apporter la garantie que notre Site ou son contenu sera toujours disponible ou disponible de manière ininterrompue. Nous pourrons donc à tout moment décider de suspendre, retirer ou modifier tout ou partie du Site sans préavis. Nous ne pourrons en aucun cas être tenus responsable en cas de difficultés ou d'impossibilité d'accès à tout ou partie du site, pour quelque raison et pour quelque durée que ce soit. </p>
        </div>
        <div className="line">
          <p>4.2</p>
          <p>Il vous appartient de prendre toutes les dispositions nécessaires pour accéder à notre Site.  </p>
        </div>
        <div className="line">
          <p>4.3</p>
          <p> Il vous appartient également de vous assurer que toutes les personnes qui accèdent à notre Site par le biais de votre connexion internet ont été informées de l'existence des présentes Conditions Générales d'Utilisation et s'engagent à les respecter.  </p>
        </div>
        <div className="line">
          <p>4.4</p>
          <p>Notre Site s'adresse aux personnes résidant sur le territoire français. Nous ne garantissons pas l'accès à notre Site aux personnes situées en dehors de ce territoire et pourrons à tout moment et pour quelque raison que ce soit limiter l'accès à notre Site ou à nos services à certaines personnes en raison de leur localisation. Si vous choisissez d'accéder à notre Site en dehors du territoire français, vous le faites à vos propres risques. </p>
        </div>
        <h3>5. Propriété intellectuelle </h3>
        <div className="line">
          <p>5.1</p>
          <p>Tous les Droits de Propriété Intellectuelle relatifs à notre Site (notamment tous les brevets, droits sur les inventions, les textes, les images, les vidéos, les séquences audio, la mise en forme, les graphismes, la structure, les marques et tout autre droit de propriété intellectuelle, dans chaque cas, qu'ils soient enregistrés ou non, y compris tou(te)s les demandes et renouvellements ou extensions de ces droits, et tou(te)s les droits similaires ou équivalents ou formes de protection dans toute partie du monde), à l'exception du contenu de votre compte, sont notre propriété exclusive ou celle de tiers titulaires de droits. </p>
        </div>
        <div className="line">
          <p>5.2</p>
          <p>Vous reconnaissez expressément notre statut (ou celui de tout contributeur identifié) de titulaire de l'ensemble des droits de propriété intellectuelle précités.  </p>
        </div>
        <div className="line">
          <p>5.3</p>
          <p>Vous vous engagez à ne pas utiliser tout ou partie du contenu ancien, actuel ou futur de notre Site à des fins commerciales sans avoir obtenu notre autorisation préalable.  </p>
        </div>
        <div className="line">
          <p>5.4</p>
          <p>Sous réserve des présentes Conditions Générales d'Utilisation, une licence non-exclusive et irrévocable d'utilisation de tout contenu, y compris toute information, photographie, graphisme ou dessin que vous téléchargez sur notre Site.  </p>
        </div>
        <h3>6. Contenu du site</h3>
        <div className="line">
          <p>6.1</p>
          <p>Les informations et contenus disponibles sur notre Site le sont à titre purement informatif. </p>
        </div>
        <div className="line">
          <p>6.2</p>
          <p>Nous faisons nos meilleurs efforts pour mettre à jour notre Site Internet. Toutefois, nous ne garantissons en aucun cas que le contenu de notre Site soit exact, exhaustif ou à jour. </p>
        </div>
        <h3>7. Responsabilité </h3>
        <div className="line">
          <p>7.1</p>
          <p>Notre responsabilité ne pourra en aucun cas être engagée en cas de perte, de dommage ou de dépense, même prévisible, du fait de votre utilisation de notre Site, du contenu disponible sur notre Site ou de votre incapacité à utiliser le Site.  </p>
        </div>
        <div className="line">
          <p>7.2</p>
          <p>Nous ne pourrons en aucun cas être tenus responsable des pertes ou des dommages causés par l'installation de virus ou de tout autre logiciel malveillant qui pourrait infecter votre équipement informatique, vos logiciels, données ou tout autre appareil par lequel vous avez accédé à notre Site ou de tout usage de notre Site ou d'un site tiers. </p>
        </div>
        <div className="line">
          <p>7.3</p>
          <p>Nous ne pourrons pas être tenus responsables en cas de perte ou de dommage résultant de l'utilisation ou du contenu de sites tiers liés sur notre Site dès lors que nous n'exerçons aucun contrôle sur le contenu de ces sites.  </p>
        </div>
        <h3>8. Sécurité du site  </h3>
        <div className="line">
          <p>8.1</p>
          <p>Nous ne garantissons en aucun cas que notre Site est sécurisé, exempt de bogues ou de virus. Il vous appartient donc de prendre toutes les mesures nécessaires pour protéger votre système informatique contre toute contamination par de potentiels virus et contre toute tentative d'intrusion par des tiers. </p>
        </div>        
        <div className="line">
          <p>8.2</p>
          <p>Vous vous interdisez, en outre, d'accéder ou de vous maintenir frauduleusement dans le Site, d'entraver ou d'altérer son fonctionnement notamment en y introduisant des virus, chevaux de troie, vers, bombes logiques ou tout autre programme malveillant et/ou susceptible de causer dommage au Site ou de porter atteinte aux données de SCORF. En cas de violation de cette disposition, nous nous réservons le droit d'alerter les forces de l'ordre, de leur divulguer votre identité et de bloquer votre accès à notre Site. </p>
        </div>
        <h3>9. Liens hypertexte </h3>      
        <div className="line">
          <p>9.1</p>
          <p>Nous vous autorisons expressément à créer un lien vers notre page d'accueil à condition que ce lien soit affiché à titre strictement informatif et ne porte en aucun cas atteinte à la loi ou à notre réputation. </p>
        </div>
        <div className="line">
          <p>9.2</p>
          <p>Vous vous interdisez de créer un lien hypertexte vers notre page d'accueil en vue de suggérer une association ou un lien économique de quelque sorte que ce soit avec SCORF sans avoir obtenu notre autorisation préalable. </p>
        </div>
        <div className="line">
          <p>9.3</p>
          <p>Nous nous réservons le droit de retirer à tout moment l'autorisation de créer un lien vers notre page d'accueil.  </p>
        </div>
        <div className="line">
          <p>9.4</p>
          <p>Notre Site contient des liens vers des sites et des ressources fournis par des tiers. Ces liens sont mis à votre disposition à titre purement informatif. Dès lors que nous n'exerçons aucun contrôle sur le contenu et les ressources des tiers, nous ne pourrons être tenus responsables du contenu mis à votre disposition par des tiers sur leurs sites ou de votre utilisation des sites des tiers. </p>
        </div>
        <h3>10. Droit applicable</h3>
        <div className="line">
          <p>10.1</p>
          <p>Les présentes Conditions Générales d'Utilisation sont soumises au droit français et tout litige qui en découlerait sera soumis à la compétence exclusive des tribunaux du ressort de la Cour d'Appel de Paris. </p>
        </div>

      </div> 
      <Footer setHeaderColor={'white'} />
    </main>
  )
}

export default CGU

